import React from 'react';
import Layout from "../../components/layout";
import {StaticImage} from "gatsby-plugin-image";

class FirstKarateTournament extends React.Component {
    render() {
        return (
            <Layout>
                <h1>First Karate Tournament</h1>
                <StaticImage src="../../images/firsttournament/ResizedImage600861-3863322.jpg"
                             alt="First karate tournament"/>
            </Layout>);
    }
}

export default FirstKarateTournament;
